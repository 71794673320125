import React from 'react'
import ImageSlider from './ImageSlider'
import P1 from '../../images/onePor.png'
import P2 from '../../images/twoPor.png'

function NewProject() {
  const images = [
    {
      src: P1,
      alt: "Project view 1",
      caption: "Full Circle - Front view"
    },
    {
      src: P2,
      alt: "Project view 2",
      caption: "Full Circle - Side view"
    }
  ];

  return (
    <div className='mt-4'>
      <h1 className='text-gray-400 text-3xl font-medium mb-4 leading-10'>New project</h1>
      
      
      
      <div className="text-white mb-[86px] mx-auto">
        <h2 className="text-[16px] text-[#9E99A5] font-[700]">Title: <span className="font-[400] text-[16px]">Full Circle</span></h2>
        <p className="mt-2 text-[16px] text-[#9E99A5] font-[700]">Material:<span className="font-[400] text-[16px]"> Repurposed vinyl banners, steel, glass, LED lights and aluminum sheets </span></p>
        <p className="mt-2 text-[16px] text-[#9E99A5] font-[700]">Location:<span className="font-[400] text-[16px]"> Living Art Centre, Mississauga, Ontario </span></p>
        <p className="mt-2 text-[16px] text-[#9E99A5] font-[700]">Dimension:<span className="font-[400] text-[16px]"> 8' x 8' x 3' (w x h x d in feet) </span></p>
        
        <p className="mt-4 mb-2 text-gray-300">
          Inspired by the Igbabonelimhin masquerade of Edo culture, this piece reflects transformation, just as I repurposed discarded banners into art. 
          Once used in Mississauga's 2022 Banner Program, these vinyl banners now symbolize a full-circle journey, inspiring sustainability and mindful creation. 
          Commissioned by the City of Mississauga.
        </p>
        
        <a 
          href="https://www.mississauga.ca/arts-and-culture/arts/public-art/temporary-public-art/full-circle/" 
          target='_blank'
          rel='noopener noreferrer'
          className="mt-4 inline-block text-purple-400 font-bold hover:underline"
        >
          View on City of Mississauga website
        </a>
        <div className='mb-4 mt-[32px]'>
        <ImageSlider images={images} />
      </div>
      </div>
    </div>
  )
}

export default NewProject